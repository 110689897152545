@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@layer base {
  html {
    font-family: 'Roboto', sans-serif;
  }
}

@font-face {
  font-family: 'barobaroRoman';
  src: url(./assets/barbaro.roman.ttf);
}

header h1 {
  font-family: 'barobaroRoman';
}

body {
  background: #F7F7F7;
}